import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import projeBinder from "../assets/img/proje-binder.png"
import projeDiyanet from "../assets/img/proje-diyanet.png"
import projeIga from "../assets/img/proje-iga.png"
import projeYildiz from "../assets/img/yildiz-entegre-icon.png"
import projeKuveyt from "../assets/img/proje-kuveyt.png"
import projeTfinans from "../assets/img/proje-tfinans.png"
import projeThy from "../assets/img/proje-thy.png"
import projeTusas from "../assets/img/proje-tusas.png"
import projeUlastirma from "../assets/img/proje-ulastirma.png"
import projeVakif from "../assets/img/proje-vakif.png"
import projeZiraat from "../assets/img/proje-ziraat.png"
import projeMaarif from "../assets/img/proje-maarif.png"
import projeOrtadogu from "../assets/img/proje-ortadogu.png"
import projeIett from "../assets/img/proje-iett.png"
import projeIski from "../assets/img/proje-iski.png"
import projeForelli from "../assets/img/proje-forelli.png"
import projeGubretas from "../assets/img/proje-gubretas.png"
import projeTei from "../assets/img/tei-icon.png"

export default function Projeler() {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title="Projeler" />
      <div className="projeler-container">
        <a href="../projeler/iga-projesi" className="proje-blok">
          <img src={projeIga} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">İGA İSTANBUL AIRPORT</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/tusas-projesi" className="proje-blok">
          <img src={projeTusas} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">TUSAŞ A.Ş.</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/yildiz-entegre" className="proje-blok">
          <img src={projeYildiz} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">YILDIZ ENTEGRE</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/diyanet-projesi" className="proje-blok">
          <img src={projeDiyanet} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">DİYANET İ.BŞK.</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/turk-hava-yollari-projesi" className="proje-blok">
          <img src={projeThy} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">{t("proje-thy")}</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/tusas-motor-sanayii" className="proje-blok">
          <img src={projeTei} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">TUSAŞ MOTOR SANAYİİ</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/vakif-katilim-projesi" className="proje-blok">
          <img src={projeVakif} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">VAKIF KATILIM</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/ulastirma-bakanligi-projesi" className="proje-blok">
          <img src={projeUlastirma} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">ULAŞTIRMA BAKANLIĞI</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/turkiye-finans-projesi" className="proje-blok">
          <img src={projeTfinans} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">TÜRKİYE FİNANS</div>
            <div className="proje-blokKind">
              {t("project-page-takvim")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/kuveytturk-projesi" className="proje-blok">
          <img src={projeKuveyt} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">KUVEYT TÜRK KATILIM</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/ziraat-katilim-projesi" className="proje-blok">
          <img src={projeZiraat} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">ZİRAAT KATILIM</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/maarif-projesi" className="proje-blok">
          <img src={projeMaarif} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">MAARİF VAKFI</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/ortadogu-projesi" className="proje-blok">
          <img src={projeOrtadogu} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">ORTADOĞU GRUP</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/iett-projesi" className="proje-blok">
          <img src={projeIett} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">İETT</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/iski-projesi" className="proje-blok">
          <img src={projeIski} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">İSKİ</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/forelli-projesi" className="proje-blok">
          <img src={projeForelli} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">FORELLİ AYAKKABI</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/gubretas-projesi" className="proje-blok">
          <img src={projeGubretas} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">GÜBRETAŞ A.Ş.</div>
            <div className="proje-blokKind">
              {t("project-page-sanat")} {t("project-page")}
            </div>
          </div>
        </a>
        <a href="../projeler/binder-projesi" className="proje-blok">
          <img src={projeBinder} alt="" />
          <div className="proje-blokDetay">
            <div className="proje-blokName">BİNDER</div>
            <div className="proje-blokKind">
              {t("project-page-birth")} {t("project-page")}
            </div>
          </div>
        </a>
      </div>
    </Layout>
  )
}
